<template>
  <v-container>
    <v-card class="ma-auto">
      <v-tabs v-model="tab" background-color="primary" >
        <v-tab value="1" >FAQ</v-tab>
        <v-tab value="2">Темы</v-tab>
      </v-tabs>
      <v-card-text class="align-self-stretch  ma-0 pa-0">
        <v-window v-model="tab" class="align-self-stretch">
          <v-window-item value="1" class="align-self-stretch">
            <v-card-title class="card_ligth_header ma-0 pa-0" >
              <v-row cols=6 class="pa-2 ma-2">
                <v-col cols="12">
                  <h3>FAQ</h3>
                </v-col>
                <v-col cols=6  >
                  <v-select
                      v-model="faqSearchField"
                      :items="faqSearchOptions"
                      label="Поле поиска"
                      clearable
                      item-title="name"
                      item-value="value"
                      variant="outlined"
                      class="small"
                  ></v-select>
                </v-col>
                <v-col cols=6>
                  <v-text-field
                      v-model="faqSearchValue"
                      label="Что ищем"
                      variant="outlined"
                      clearable
                      rounded
                      append-icon="mdi-account-plus"
                      @click:append="faqDialog=true"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text clas="ma-0 pa-0" >
              <v-row>
                <v-col cols="12">
                  <Vue3EasyDataTable
                      :headers="faqHeaders"
                      :items="faqs"
                      alternating
                      show-index
                      :search-field="faqSearchField"
                      :search-value="faqSearchValue"
                  >
                    <template #item-createDateTime="{createDateTime}">
                      {{ createDateTime.replace('T', ' ')}}
                    </template>
                    <template #item-active="{active}">
                      <p v-if="active">Да</p>
                      <p v-else>Нет</p>
                    </template>
                    <template #item-faqTheme="{faqTheme}">
                      <p>{{faqTheme.name}}</p>
                    </template>


                    <template #item-actions="item">
                      <font-awesome-icon style="margin: 5px; color: #1E88E5" @click="editFaq(item)" icon="fa-solid fa-pen-to-square" />
                      <font-awesome-icon style="margin: 5px; color: darkred" @click="deleteFaq(item)" icon="fas fa-trash-alt"/>
                    </template>
                    <template #loading>
                      <img
                          src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif"
                          style="width: 100px; height: 80px;"
                      />
                    </template>

                  </Vue3EasyDataTable>
                </v-col>
              </v-row>

            </v-card-text>
            <v-divider></v-divider>
          </v-window-item>
          <v-window-item value="2" class="align-self-stretch">
            <v-card-title class="card_ligth_header ma-0 pa-0">
              <v-row  class=" ma-2 pa-2" >
                <v-col cols="12">
                  <h3>Темы</h3>
                </v-col>
                <v-col cols=6>
                  <v-select
                      v-model="faqThemeSearchField"
                      :items="faqThemeSearchOptions"
                      label="Поле поиска"
                      clearable
                      item-title="name"
                      item-value="value"
                      variant="outlined"
                      class="small"
                  ></v-select>
                </v-col>
                <v-col cols=6>
                  <v-text-field
                      v-model="faqThemeSearchValue"
                      label="Что ищем"
                      variant="outlined"
                      clearable
                      rounded
                      append-icon="mdi-account-multiple-plus"
                      @click:append="faqThemeDialog=true"
                  >
                  </v-text-field>
                </v-col>

              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="ma-0 pa-0" >
              <v-row>
                <v-col cols="12">
                  <Vue3EasyDataTable
                      :headers="faqThemeHeaders"
                      :items="faqThemes"
                      alternating
                      show-index
                      :search-field="faqSearchField"
                      :search-value="faqSearchValue"
                  >

                    <template #item-createDateTime="{createDateTime}">
                      {{ createDateTime.replace('T', ' ')}}
                    </template>
                    <template #item-active="{active}">
                      <p v-if="active">Да</p>
                      <p v-else>Нет</p>
                    </template>

                    <template #item-actions="item">
                      <font-awesome-icon style="margin: 5px; color: #1E88E5" @click="editFaqTheme(item)" icon="fa-solid fa-pen-to-square" />
                      <font-awesome-icon style="margin: 5px; color: darkred" @click="deleteFaqTheme(item)" icon="fas fa-trash-alt"/>
                    </template>
                    <template #loading>
                      <img
                          src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif"
                          style="width: 100px; height: 80px;"
                      />
                    </template>

                  </Vue3EasyDataTable>
                </v-col>
              </v-row>

            </v-card-text>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
  </v-container>

  <v-dialog
      v-model="faqDialog"
      scrollable
  >
    <v-card max-width="500"
            min-width="500">
      <v-card-title class="card_ligth_header">
        <h5>Темы</h5>
      </v-card-title>
      <v-card-text>
        <v-form
            ref="faqForm"
        >
          <v-container>
            <v-row>
              <v-col
                  cols="12"
              >
                <v-textarea
                    label="Вопрос*"
                    v-model="question"
                    :rules="questionRules"
                    hint="Укажите вопрос"
                    :counter="250"
                    variant="outlined"
                    required
                ></v-textarea>
              </v-col>

              <v-col
                  cols="12"
              >
                <v-textarea
                    label="Ответ*"
                    v-model="answer"
                    :rules="answerRules"
                    hint="Укажите ответ"
                    :counter="250"
                    variant="outlined"
                    required
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-select
                    v-model="faqTheme"
                    :items="activeThemes"
                    :rules="themeRules"
                    outlined
                    dense
                    variant="outlined"
                    label="Тема"
                    item-title="name"
                    item-value="id"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-select
                    v-model="active"
                    :items="activeValues"
                    outlined
                    dense
                    variant="outlined"
                    label="Активность"
                    item-title="name"
                    item-value="value"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <v-btn
            rounded
            variant="outlined"
            @click="closeFaqForm"
        >
          Отмена
        </v-btn>
        <v-btn
            rounded
            variant="outlined"
            @click="saveFaq"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog
      v-model="faqThemeDialog"
      scrollable
  >
    <v-card max-width="500"
            min-width="500" >
      <v-card-title class="card_ligth_header">
        <h5>Тема Faq</h5>
      </v-card-title>
      <v-card-text>
        <v-form
            ref="faqThemeForm"
        >
          <v-container>
            <v-row>
              <v-col
                  cols="12"
              >
                <v-text-field
                    label="Название*"
                    v-model="name"
                    :rules="faqThemeNameRules"
                    hint="Укажите название ntvs"
                    :counter="50"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>


              <v-col cols="12">
                <v-container
                    class="px-0"
                    fluid
                >
                  <v-switch
                      v-model="active"
                      :label="active ? 'Активна' : 'Не активна'"
                  ></v-switch>
                </v-container>
              </v-col>

            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <v-btn
            rounded
            variant="outlined"
            @click="closeFaqThemeForm"
        >
          Отмена
        </v-btn>
        <v-btn
            rounded
            variant="outlined"
            @click="saveFaqTheme"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
      v-model="showLoader"
      persistent
  ><v-alert><span class="text-h5 text-primary">Загрузка данных <v-progress-circular
      indeterminate
      color="primary"
  ></v-progress-circular></span></v-alert>
  </v-dialog>
</template>

<script>

import {apiUrl} from "@/service/user.service"
import {router} from "@/service/router";


import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list,id) {
  for (var i =0; i< list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

export default {
  name: 'FaqAdminPage',

  props: [],
  components:{
    Vue3EasyDataTable
  },


  data: () => ({
    faqDialog: false,

    faqs:[],
    faqThemes:[],
    faqHeaders: [
      { text: "id", value: "id" },
      { text: "Вопрос", value: "question" },
      { text: "Ответ", value: "answer" , sortable: true},
      { text: "Тема", value: "faqTheme" , sortable: true},
      { text: "Активен", value: "active", sortable: true},
      { text: "Добавлен", value: "createDateTime" , sortable: true},
      { text: "Действие", value: "actions"},
    ],
    faqSearchOptions: [
      {value: "question", name: "Вопрос"},
      {value: "answer", name: "Ответ"},
      {value: "faqTheme", name: "Тема"},
      {value: "Активен", name: "active"},
    ],
    faqThemeHeaders: [
      { text: "id", value: "id" },
      { text: "Название", value: "name" },
      { text: "Активен", value: "active", sortable: true},
      { text: "Добавлен", value: "createDateTime" , sortable: true},
      { text: "Действие", value: "actions"},
    ],
    faqThemeSearchOptions: [
      {value: "Название", name: "name"},
      {value: "Активен", name: "active"},
    ],

    faqSearchField:'',
    faqSearchValue:'',

    faqThemeSearchField:'',
    faqThemeSearchValue:'',


    name:'',
    active:false,
    question:'',
    answer:'',
    faqTheme: null,
    activeValues: [
      { name: "Да", value: true },
      { name: "Нет", value: false }
    ],
    showLoader: false,
    questionRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 250) || 'Значение до должно превышать 250 символов!',
    ],
    answerRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 250) || 'Значение до должно превышать 250 символов!',
    ],
    themeRules: [
      v => !!v || 'Поле обязательно для заполнения!'
       ],
    faqThemeNameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],

    id:null,
    loading: false,
    faqThemeDialog:false,
    tab:1,

  }),
  methods: {


    editFaq: function (item) {
      this.id = item.id
      this.active = item.active
      this.answer = item.answer
      this.question = item.question
      this.faqTheme = item.faqTheme
      this.faqDialog = true
    },
    editFaqTheme: function (item) {
      this.id = item.id
      this.active = item.active
      this.name = item.name
      this.faqThemeDialog = true
    },
    deleteFaqTheme: function (item) {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      this.axios
          .delete(apiUrl +'/faq/theme/' + item.id, jwtTokenConfig)
          .then(response => {

            if (response.status === 202) {
              this.faqThemes.splice(this.faqThemes.indexOf(item), 1)
            } else if (response.status === 207) {
              alert(response.data.message)
            } else {
              console.log(response)
            }

          })
          .catch(error=> {
            console.log(error)
          })
    },
    deleteFaq: function (item) {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      this.axios
          .delete(apiUrl +'/faq/' + item.id, jwtTokenConfig)
          .then(response => {

            if (response.status === 202) {
              this.faqs.splice(this.faqs.indexOf(item), 1)
            } else if (response.status === 207) {
              console.log(response)
            } else {
              console.log(response)
            }

          })
          .catch(error=> {
            console.log(error)
          })
    },
    saveFaq() {

      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      this.$refs.faqForm.validate().then(response =>{
        if(response.valid){
          if(this.faqTheme instanceof  Object){
            this.faqTheme = this.faqTheme.id;
          }
          let faq = {
            id: this.id,
            answer: capitalizeFirstLetter(this.answer),
            question: capitalizeFirstLetter(this.question),
            active: this.active,
            themeId: this.faqTheme

          }

          if(this.id){
            this.axios.put(apiUrl +'/faq/' + this.id, faq, jwtTokenConfig)
                .then(response => {

                  if (response.status === 202) {
                    let index = getIndex(this.faqs, response.data.id)
                    this.faqs.splice(index, 1, response.data)
                    this.faqDialog = false
                    this.active = false
                    this.question = ''
                    this.answer = ''
                    this.faqTheme = null
                    this.themeId = null
                    this.id = null
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }

                })
                .catch(error=> {
                  if(error.response.status===401){
                    this.$store.dispatch('authentication/logout')
                    this.$store.dispatch('alert/clear')
                    localStorage.clear()
                    router.push('/').then(r => {console.log("error: " + r)})
                  }
                })
          } else {
            this.axios.post(apiUrl +'/faq', faq, jwtTokenConfig)
                .then(response => {

                  if (response.status === 201) {
                    this.faqs.push(response.data)
                    this.faqDialog = false
                    this.active = false
                    this.faqTheme = null
                    this.question = ''
                    this.answer = ''
                    this.themeId = null
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }

                })
                .catch(error=> {
                  if(error.response.status===401){
                    this.$store.dispatch('authentication/logout')
                    this.$store.dispatch('alert/clear')
                    localStorage.clear()
                    router.push('/').then(r => {console.log("error: " + r)})
                  }

                })
          }
        }






      })


    },
    saveFaqTheme() {

      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      this.$refs.faqThemeForm.validate().then(responce =>{
        if(responce.valid){

          let faqTheme = {
            id: this.id,
            name: capitalizeFirstLetter(this.name),
            active: this.active,
          }

          if(this.id){
            this.axios.put(apiUrl +'/faq/theme/' + this.id, faqTheme, jwtTokenConfig)
                .then(response => {

                  if (response.status === 202) {
                    let index = getIndex(this.faqThemes, response.data.id)
                    this.faqThemes.splice(index, 1, response.data)
                    this.faqThemeDialog = false
                    this.active = false
                    this.name = ''
                    this.id = null
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }

                })
                .catch(error=> {
                  if(error.response.status===401){
                    this.$store.dispatch('authentication/logout')
                    this.$store.dispatch('alert/clear')
                    localStorage.clear()
                    router.push('/').then(r => {console.log("error: " + r)})
                  }
                })
          } else {
            this.axios.post(apiUrl +'/faq/theme', faqTheme, jwtTokenConfig)
                .then(response => {

                  if (response.status === 201) {
                    this.faqThemes.push(response.data)
                    this.faqThemeDialog = false
                    this.active = false
                    this.name = ''
                    this.id = null
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }

                })
                .catch(error=> {
                  if(error.response.status===401){
                    this.$store.dispatch('authentication/logout')
                    this.$store.dispatch('alert/clear')
                    localStorage.clear()
                    router.push('/').then(r => {console.log("error: " + r)})
                  }

                })
          }
        }






      })


    },
    closeFaqThemeForm(){
      this.faqThemeDialog = false
      this.active = false
      this.name = ''
      this.id = null
    },
    closeFaqForm(){
      this.faqDialog = false
      this.active = false
      this.question = ''
      this.answer = ''
      this.themeId = null
      this.faqTheme = null
      this.id = null
    },

  },
  computed: {

    jwtHeaders(){
      let jwtTokenConfig = {
        method: 'POST',
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
        responseType: 'blob'
      }
      return jwtTokenConfig
    },
    activeThemes(){
      let sourceThemes = this.faqThemes
      return sourceThemes.filter(item => item.active===true)
    },
    loggedIn() {
      return this.$store.state.authentication.status.loggedIn
    },
    user() {
      return this.$store.state.authentication.user
    },
    authentication() {
      return this.$store.state.authentication
    },

  },
  created: function () {
    this.showLoader=true
    let jwtTokenConfig = {
      headers: {
        Authorization: "Bearer " + this.user.token
      }
    }

    function getFaq(jwtTokenConfig, axios) {
      return axios.get(apiUrl +'/faq/all', jwtTokenConfig)
    }

    function getFaqThemes(jwtTokenConfig, axios) {
      return axios.get(apiUrl +'/faq/theme', jwtTokenConfig)
    }

    Promise.all([
      getFaq(jwtTokenConfig, this.axios),
      getFaqThemes(jwtTokenConfig, this.axios),

    ])
        .then(results => {

          if(results[0].status===200){
            this.faqs = results[0].data;
          }
          if(results[1].status===200){
            this.faqThemes = results[1].data;
          }
          this.showLoader=false
        }).catch(error=> {
      this.showLoader=false
      alert("Ошибка загрузки данных, обратитесь к администратору системы!")
      if(error.response.status===401){
        this.$store.dispatch('authentication/logout')
        this.$store.dispatch('alert/clear')
        localStorage.clear()
        router.push('/').then(r => {console.log("error: " + r)})

      }
    });
  }
}



</script>
<style>

.avatar-cropper-close {
  margin-top: 60px !important;
}
.card-img-overlay {
  display: none;
  transition: all 0.5s;
}
.card-img-overlay button {
  margin-top: 20vh;
}
.card:hover .card-img-overlay {
  display: block;
}

.avatar-cropper-close {
  float: right;
  padding: 20px;
  font-size: 3rem;
  color: #263238  !important;
  font-weight: 100;
  text-shadow: 0px 1px rgba(40, 40, 40, 0.3);
}

.avatar-cropper-btn {
  width: 50%;
  padding: 15px 0;
  cursor: pointer;
  border: none;
  outline: none;
  color: #263238  !important;
  background-color: #fff  !important;
}

.avatar-cropper-btn:hover {
  width: 50%;
  padding: 15px 0;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #263238  !important;
  color: #fff  !important;
}

</style>
