<template>
  <v-container>
    <v-card class="ma-auto">
          <v-tabs v-model="tab" background-color="primary" >
            <v-tab value="1" >Пользователи</v-tab>
            <v-tab value="2">Группы пользователей</v-tab>
          </v-tabs>
          <v-card-text class="align-self-stretch  ma-0 pa-0">
            <v-window v-model="tab" class="align-self-stretch">
              <v-window-item value="1" class="align-self-stretch">
                <v-card-title class="card_ligth_header ma-0 pa-0" >
                  <v-row cols=6 class="pa-2 ma-2">
                    <v-col cols="12">
                      <h3>Пользователи</h3>
                    </v-col>
                    <v-col cols=6  >
                      <v-select
                          v-model="searchField"
                          :items="userSearchOptions"
                          label="Поле поиска"
                          clearable
                          item-title="name"
                          item-value="value"
                          variant="outlined"
                          class="small"
                      ></v-select>
                    </v-col>
                    <v-col cols=6>
                      <v-text-field
                          v-model="searchValue"
                          label="Что ищем"
                          variant="outlined"
                          clearable
                          rounded
                          append-icon="mdi-account-plus"
                          @click:append="dialog=true"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text v-if="showTable" clas="ma-0 pa-0" >
                  <v-row>
                    <v-col cols="12">
                      <Vue3EasyDataTable
                          :headers="headers"
                          :items="users"
                          alternating
                          show-index
                          :search-field="searchField"
                          :search-value="searchValue"
                      >
                        <template #item-personAvatar="{personAvatar, id}">



                          <v-row align="center" class="spacer" no-gutters>
                            <v-col cols="3">
                              <v-avatar
                                  size="32px"
                                  color="primary"
                                  @click="addAvatar(id)"
                              >
                                <v-img v-if="personAvatar!==null"
                                    :src="'https://navigator-backend.geekbase.ru/api/download/avatar/user/' + personAvatar"

                                ></v-img>
                              </v-avatar>
                            </v-col>

                          </v-row>


                        </template>
                        <template #item-name="{firstname, lastname}">
                            <strong>{{firstname}} {{lastname}}</strong>
                        </template>

                        <template #item-order="{id}">
                          {{ id}}
                        </template>

                        <template #item-registrationDate="{registrationDate}">
                          {{ registrationDate.replace('T', ' ')}}
                        </template>

                        <template #item-actions="item">
                          <font-awesome-icon style="margin: 5px; color: #1E88E5" @click="editUser(item)" icon="fa-solid fa-pen-to-square" />
                          <font-awesome-icon style="margin: 5px; color: darkred" @click="deleteUser(item)" icon="fas fa-trash-alt"/>
                        </template>
                        <template #loading>
                          <img
                              src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif"
                              style="width: 100px; height: 80px;"
                          />
                        </template>

                      </Vue3EasyDataTable>
                    </v-col>
                  </v-row>

                </v-card-text>
                <v-divider></v-divider>
              </v-window-item>
              <v-window-item value="2" class="align-self-stretch">
                <v-card-title class="card_ligth_header ma-0 pa-0">
                  <v-row  class=" ma-2 pa-2" >
                    <v-col cols="12">
                      <h3>Группы пользователей</h3>
                    </v-col>
                    <v-col cols=6>
                      <v-select
                          v-model="groupSearchField"
                          :items="userGroupSearchOptions"
                          label="Поле поиска"
                          clearable
                          item-title="name"
                          item-value="value"
                          variant="outlined"
                          class="small"
                      ></v-select>
                    </v-col>
                    <v-col cols=6>
                      <v-text-field
                          v-model="groupSearchValue"
                          label="Что ищем"
                          variant="outlined"
                          clearable
                          rounded
                          append-icon="mdi-account-multiple-plus"
                          @click:append="groupDialog=true"
                      >
                      </v-text-field>
                    </v-col>

                  </v-row>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="ma-0 pa-0" >
                  <v-row>
                    <v-col cols="12">
                      <Vue3EasyDataTable
                          :headers="groupHeaders"
                          :items="userGroups"
                          alternating
                          show-index
                          :search-field="groupSearchField"
                          :search-value="groupSearchValue"
                      >

                        <template #item-personGroupCreateDate="{personGroupCreateDate}">
                          {{ personGroupCreateDate.replace('T', ' ')}}
                        </template>
                        <template #item-isLocked="{isLocked}">
                          <p v-if="isLocked">Да</p>
                          <p v-else>Нет</p>
                        </template>

                        <template #item-actions="item">
                          <font-awesome-icon style="margin: 5px; color: #1E88E5" @click="editUserGroup(item)" icon="fa-solid fa-pen-to-square" />
                          <font-awesome-icon style="margin: 5px; color: darkred" @click="deleteUserGroup(item)" icon="fas fa-trash-alt"/>
                        </template>
                        <template #loading>
                          <img
                              src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif"
                              style="width: 100px; height: 80px;"
                          />
                        </template>

                      </Vue3EasyDataTable>
                    </v-col>
                  </v-row>

                </v-card-text>
              </v-window-item>
            </v-window>
          </v-card-text>
    </v-card>
  </v-container>

  <v-dialog
     v-model="dialog"
     scrollable
  >
    <v-card max-width="500"
            min-width="350">
      <v-card-title class="card_ligth_header">
         <h5>Пользователь</h5>
      </v-card-title>
              <v-card-text>
                <v-form
                  ref="userForm"
                 >
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                      >
                        <v-text-field
                      label="Фамилия*"
                      v-model="lastname"
                      :rules="lastnameRules"
                      hint="Укажите фамилию"
                      :counter="25"
                      variant="outlined"
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                >
                  <v-text-field
                      label="Имя*"
                      v-model="firstname"
                      :rules="firstnameRules"
                      :counter="25"
                      variant="outlined"
                      hint="Укажите имя"
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                >
                  <v-text-field
                      label="Отчество*"
                      v-model="secname"
                      :rules="secnameRules"
                      variant="outlined"
                      :counter="25"
                      hint="Укажите отчество"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="personEmail"
                      variant="outlined"
                      label="Email*"
                      hint="Укажите e-mail"
                  ></v-text-field>
                  <v-alert
                      density="compact"
                      type="error"
                      variant="outlined"
                      v-show="existEmail"
                  >E-mail уже занят!
                  </v-alert>
                </v-col>
                      <v-col cols="12">
                        <v-text-field
                            v-model="username"
                            :rules="usernameRules"
                            variant="outlined"
                            label="Имя пользователя*"
                            hint="Укажите имя пользователя (используется для входа в систему)"
                            required
                        ></v-text-field>
                      </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="password"
                      label="Пароль*"
                      contenteditable="false"
                      type="text"
                      variant="outlined"
                      hint="Сгенерированный пароль"
                      required
                      persistent
                  >
                    <template v-slot:append>
                      <v-icon
                          color="red"
                          v-on:click="generatePassword"
                      >
                        mdi-hammer
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                      v-model="roles"
                      :items="rolesItems"
                      outlined
                      dense
                      chips
                      small-chips
                      variant="outlined"
                      closable-chips
                      label="Роли"
                      multiple
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                      v-model="personGroups"
                      :items="userGroups"
                      :custom-filter="userGroupFilter"
                      filled
                      chips
                      small-chips
                      variant="outlined"
                      closable-chips
                      label="Группы пользователей"
                      item-title="name"
                      item-value="name"
                      multiple
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.personGroupName"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">

                      <v-list-item

                          v-bind="props"
                          :title="item.raw.personGroupName"
                          :subtitle="item.raw.personGroupDescription"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>

              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn
              rounded
              variant="outlined"
              @click="closePersonForm"
          >
            Отмена
          </v-btn>
          <v-btn
              rounded
              variant="outlined"
              @click="save"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

  <avatar-cropper
      @uploaded="handleUploaded"
      @error="handlerError"
      v-model="showCropper"
      :upload-url="'https://navigator-backend.geekbase.ru/api/upload/avatar/user/'+id"
      :request-options="jwtHeaders"
      :labels="labels"
      mimes="image/gif, image/jpeg, image/png, image/bmp"
  />




  <v-dialog
      v-model="groupDialog"
      scrollable
  >
    <v-card max-width="500"
            min-width="350" >
      <v-card-title class="card_ligth_header">
        <h5>Группа пользователей</h5>
      </v-card-title>
      <v-card-text>
        <v-form
            ref="userGroupForm"
        >
          <v-container>
            <v-row>
              <v-col
                  cols="12"
              >
                <v-text-field
                    label="Название*"
                    v-model="personGroupName"
                    :rules="personGroupNameRules"
                    hint="Укажите название группы"
                    :counter="30"
                    required
                    variant="outlined"
                ></v-text-field>
                <v-alert
                    density="compact"
                    type="error"
                    v-show="existGroupError"
                >Группа уже существует!
                </v-alert>
              </v-col>
              <v-col
                  cols="12"
              >
                <v-textarea
                    label="Описание*"
                    v-model="personGroupDescription"
                    :rules="personGroupDescriptionRules"
                    :counter="255"
                    hint="Укажите описание"
                    variant="outlined"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-container
                    class="px-0"
                    fluid
                >
                  <v-switch
                      v-model="isLocked"
                      :label="isLocked ? 'Заблокирована' : 'Активна'"
                  ></v-switch>
                </v-container>
              </v-col>

            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <v-btn
            rounded
            variant="outlined"
            @click="closeUserGroupForm"
        >
          Отмена
        </v-btn>
        <v-btn
            rounded
            variant="outlined"
            @click="saveGroup"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
      v-model="showLoader"
      persistent
  ><v-alert><span class="text-h5 text-primary">Загрузка данных <v-progress-circular
      indeterminate
      color="primary"
  ></v-progress-circular></span></v-alert>
  </v-dialog>
</template>

<script>

import {apiUrl} from "@/service/user.service"
import {router} from "@/service/router";
import AvatarCropper from 'vue-avatar-cropper';

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list,id) {
  for (var i =0; i< list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

export default {
  name: 'AdminUserPage',

  props: [],
  components:{
    Vue3EasyDataTable,
    AvatarCropper
  },


  data: () => ({

    groupSearchField:'',
    groupSearchValue:'',
    labels:{ submit: 'Сохранить', cancel: 'Отмена' },

    showCropper:false,
    showTable: true,



    userSearchOptions: [
      {value: "name", name: "Имя"},
      {value: "personEmail", name: "Email"},
      {value: "username", name: "Логин"},
      {value: "telegramBotChatId", name: "Telegram"},
      {value: "roles", name: "Роли"},
    ],

    userGroupSearchOptions: [
      {value: "personGroupName", name: "Имя"},
      {value: "personGroupDescription", name: "Описание"},
      {value: "personGroupCreateDate", name: "Добавлена"},
      {value: "isLocked", name: "Активна"},
    ],

    headers: [
    { text: "id", value: "id" },
    { text: "Аватар", value: "personAvatar" },
    { text: "Имя", value: "name" , sortable: true},
    { text: "Email", value: "personEmail" , sortable: true},
    { text: "Логин", value: "username" , sortable: true},
    { text: "Tg id", value: "telegramBotChatId" , sortable: true},
    { text: "Роли", value: "roles", sortable: true},
    { text: "Зарегистрирован", value: "registrationDate" , sortable: true},
    { text: "Действие", value: "actions"},
  ],
    groupHeaders: [
      { text: "id", value: "id" },
      { text: "Имя", value: "personGroupName" , sortable: true  },
      { text: "Описание", value: "personGroupDescription" , sortable: true},
      { text: "Добавлена", value: "personGroupCreateDate" , sortable: true},
      { text: "Активна", value: "isLocked" , sortable: true},
      { text: "Действие", value: "actions"},
    ],
    searchField: "name",
    searchValue:'',





    showLoader: false,
    firstnameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],
    lastnameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],
    secnameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],
    usernameRules: [
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],

    rolesItems: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_PARENT', 'ROLE_CHILD','ROLE_COACH', 'ROLE_TUTOR', 'ROLE_MASTER'],
    firstname: '',
    id:null,
    page: 1,
    noteCount: 20,
    lastname: '',
    secname: '',
    password: '',
    personEmail: '',
    personGroups: [],
    roles: [],
    //personGroupsSource:[],
    valid: true,
    dialog: false,
    searchBody: '',
    showInput: false,
    loading: false,
    users: [],
    emailForCheck: {},
    existEmail: false,
    username: '',

    groupSearchBody:'',
    groupDialog:false,
    personGroupName:'',
    personGroupDescription:'',
    isLocked:false,
    existGroupError:false,
    personGroupNameForCheck:'',
    groupPage:1,
    userGroups: [],
    tab:1,
    personGroupNameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 30) || 'Значение до должно превышать 30 символов!',
    ],
    personGroupDescriptionRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 255) || 'Значение до должно превышать 255 символов!',
    ],
    userSortKey: 'id',
    userLastSortKey: 'id',
    userSortOrder: 1,


    userGroupSortKey: 'id',
    userGroupLastSortKey: 'id',
    userGroupSortOrder: 1,




  }),
  methods: {
    addAvatar(item){
      this.id = item;
      this.showTable = false;
      this.showCropper = true;

    },


    handleUploaded(res) {
      console.log("handleUploaded " + res)
      if (res.response.status === 201) {

        let jwtTokenConfig = {
          headers: {
            Authorization: "Bearer " + this.user.token
          }
        }

        this.axios
            .get(apiUrl + '/person', jwtTokenConfig)
            .then(res => {
              if(res.status===200){
                this.users=res.data
                this.showTable = true


              }

            }).catch(error => {
          if(error.response.status===401){
            this.$store.dispatch('authentication/logout')
            this.$store.dispatch('alert/clear')
            localStorage.clear()
            location.reload()

          }
        })

      }
    },



    handlerError() {
      this.message = "Oops! Something went wrong...";
      console.log("MSG: " + this.message)
    },


    editUserGroup: function (event) {
      this.id = event.id
      this.personGroupName = event.personGroupName
      this.personGroupDescription = event.personGroupDescription
      this.isLocked = event.isLocked
      this.groupDialog = true
    },
    deleteUserGroup: function (item) {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      this.axios
          .delete(apiUrl +'/person_group/' + item.id, jwtTokenConfig)
          .then(response => {
          
            if (response.status === 202) {
              this.userGroups.splice(this.userGroups.indexOf(item), 1)
            } else {
              console.log(response)
            }
          })
          .catch(error=> {
            console.log(error)
          })
    },
    closeUserGroupForm() {
      this.groupDialog = false
      this.personGroupName = ''
      this.personGroupDescription = ''
      this.isLocked = false
    },
    saveGroup() {

      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      this.$refs.userGroupForm.validate().then(responce =>{
        if(responce.valid){

          let userGroup = {
            id: this.id,
            personGroupName: capitalizeFirstLetter(this.personGroupName),
            personGroupDescription: capitalizeFirstLetter(this.personGroupDescription),
            isLocked: this.isLocked,
          }

          if(this.id){
            this.axios.put(apiUrl +'/person_group/' + this.id, userGroup, jwtTokenConfig)
                .then(response => {

                  if (response.status === 202) {
                    let index = getIndex(this.userGroups, response.data.id)
                    this.userGroups.splice(index, 1, response.data)
                    this.groupDialog = false
                    this.id = null
                    this.personGroupName = ''
                    this.personGroupDescription = ''
                    this.isLocked = false
                    this.existGroupError = false
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }

                })
                .catch(error=> {
                  if(error.response.status===401){
                    this.$store.dispatch('authentication/logout')
                    this.$store.dispatch('alert/clear')
                    localStorage.clear()
                    router.push('/').then(r => {console.log("error: " + r)})
                  }
                })
          } else {
            this.axios.post(apiUrl +'/person_group', userGroup, jwtTokenConfig)
                .then(response => {

                  if (response.status === 201) {
                    this.userGroups.push(response.data)
                    this.groupDialog = false
                    this.personGroupName = ''
                    this.personGroupDescription = ''
                    this.isLocked = false
                    this.existGroupError = false
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }

                })
                .catch(error=> {
                  if(error.response.status===401){
                    this.$store.dispatch('authentication/logout')
                    this.$store.dispatch('alert/clear')
                    localStorage.clear()
                    router.push('/').then(r => {console.log("error: " + r)})
                  }

                })
          }
        }






      })


    },

    closePersonForm() {

      this.firstname = ''
      this.lastname = ''
      this.secname = ''
      this.personEmail = ''
      this.password = ''
      this.active = ''
      this.userEmail = ''
      this.roles = []
      this.personGroups = []
      this.id = null
      this.dialog = false


    },
    clearSearch() {
      this.searchBody = ''
    },
    generatePassword() {
      let text = ""
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

      for (var i = 0; i < 13; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length))

      this.password = text
    },
    save() {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }

      this.$refs.userForm.validate().then(responce =>{
        if(responce.valid){
          if(this.personEmail.length==0) {
              let person = {
                firstname: capitalizeFirstLetter(this.firstname),
                lastname: capitalizeFirstLetter(this.lastname),
                secname: capitalizeFirstLetter(this.secname),
                username: this.username,
                personEmail: this.personEmail,
                password: this.password,
                active: this.active,
                userEmail: this.userEmail,
                roles: this.roles,
                personGroups: this.personGroups,
              }
              if(this.id){
                this.axios.put(apiUrl +'/person/' + this.id, person, jwtTokenConfig)
                    .then(response => {
                    
                      if (response.status === 202) {
                        let index = getIndex(this.users, response.data.id)
                        this.users.splice(index, 1, response.data)
                        this.dialog = false
                        this.firstname = ''
                        this.lastname = ''
                        this.secname = ''
                        this.personEmail = ''
                        this.password = ''
                        this.active = ''
                        this.userEmail = ''
                        this.username = ''
                        this.roles = []
                        this.personGroups = []
                        this.id = null
                      } else if (response.status === 207) {
                        alert(response.data.message)
                        console.log(response)
                      } else {
                        alert("что то пошло не так")
                        console.log(response)
                      }
                    })
                    .catch(error=> {
                      console.log(error)
                    })
              } else {
                this.axios.post(apiUrl +'/person', person, jwtTokenConfig)
                    .then(response => {
                    
                      if (response.status === 201) {
                        this.users.push(response.data)
                        this.dialog = false
                        this.firstname = ''
                        this.lastname = ''
                        this.secname = ''
                        this.personEmail = ''
                        this.password = ''
                        this.active = ''
                        this.userEmail = ''
                        this.username = ''
                        this.roles = []
                        this.personGroups = []
                      }else if (response.status === 207) {
                        alert(response.data.message)
                        console.log(response)
                      } else {
                        alert("что то пошло не так")
                        console.log(response)
                      }


                    })
                    .catch(error=> {
                      console.log(error)
                    })
              }

            } else {

                  let person = {
                    firstname: capitalizeFirstLetter(this.firstname),
                    lastname: capitalizeFirstLetter(this.lastname),
                    secname: capitalizeFirstLetter(this.secname),
                    username: this.username,
                    personEmail: this.personEmail,
                    password: this.password,
                    active: this.active,
                    userEmail: this.userEmail,
                    roles: this.roles,
                    personGroups: this.personGroups,
                  }



                    if (this.id) {
                      this.axios.put(apiUrl +'/person/' + this.id, person, jwtTokenConfig)
                          .then(response => {
                          
                            if (response.status === 202) {
                              let index = getIndex(this.users, response.data.id)
                              this.users.splice(index, 1, response.data)
                              this.dialog = false
                              this.firstname = ''
                              this.lastname = ''
                              this.secname = ''
                              this.personEmail = ''
                              this.password = ''
                              this.active = ''
                              this.userEmail = ''
                              this.username = ''
                              this.roles = []
                              this.personGroups = []
                              this.id = null
                            } else if (response.status === 207) {
                              alert(response.data.message)
                              console.log(response)
                            } else {
                              alert("что то пошло не так")
                              console.log(response)
                            }


                          })
                          .catch(error=> {
                            console.log(error)
                          })
                    }  else {

                      this.axios.post(apiUrl +'/person', person, jwtTokenConfig)
                          .then(response => {
                          
                            if (response.status === 201) {
                              this.users.push(response.data)
                              this.dialog = false
                              this.firstname = ''
                              this.lastname = ''
                              this.secname = ''
                              this.personEmail = ''
                              this.password = ''
                              this.active = ''
                              this.userEmail = ''
                              this.username = ''
                              this.roles = []
                              this.personGroups = []
                            } else if (response.status === 207) {
                              alert(response.data.message)
                              console.log(response)
                            } else {
                              alert("что то пошло не так")
                              console.log(response)
                            }
                          })
                          .catch(error=> {
                            if(error.response.status===401){
                              this.$store.dispatch('authentication/logout')
                              this.$store.dispatch('alert/clear')
                              localStorage.clear()
                              router.push('/').then(r => {console.log("error: " + r)})
                            }
                          })
                    }

          }

        }
      })

    },
    editUser: function (event) {
      this.id = event.id
      this.firstname = event.firstname
      this.lastname = event.lastname
      this.secname = event.secname
      this.personEmail = event.personEmail
      this.active = event.active
      this.userEmail = event.userEmail
      this.username = event.username
      this.roles = event.roles
      this.personGroups = event.personGroups
      this.dialog = true
    },
    deleteUser: function (item) {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      this.axios
          .delete(apiUrl +'/person/' + item.id, jwtTokenConfig)
          .then(response => {
          
            if (response.status === 202) {
              this.users.splice(this.users.indexOf(item), 1)
            } else {
              console.log(response)
            }
          })
          .catch(error=> {
            if(error.response.status===401){
              this.$store.dispatch('authentication/logout')
              this.$store.dispatch('alert/clear')
              localStorage.clear()
              router.push('/').then(r => {console.log("error: " + r)})
            }

          })
    },
    userGroupFilter(item, queryText){
      console.log("filter item: "+ item)
      const textPersonGroupName = item.personGroupName.toLowerCase()
      const textPersonGroupDescription = item.personGroupDescription.toLowerCase()

      const searchText = queryText.toLowerCase()

      return textPersonGroupName.indexOf(searchText) > -1 ||
          textPersonGroupDescription.indexOf(searchText) > -1

    },

  },
  computed: {
    usersList(){
      let newUsers = this.users
      return newUsers
    },


    jwtHeaders(){
      let jwtTokenConfig = {
        method: 'POST',
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
        responseType: 'blob'
      }
      return jwtTokenConfig
    },



    loggedIn() {
      return this.$store.state.authentication.status.loggedIn
    },
    user() {
      return this.$store.state.authentication.user
    },
    authentication() {
      return this.$store.state.authentication
    },

  },
  created: function () {
    this.showLoader=true
    let jwtTokenConfig = {
      headers: {
        Authorization: "Bearer " + this.user.token
      }
    }

    function getUsers(jwtTokenConfig, axios) {
      return axios.get(apiUrl +'/person', jwtTokenConfig)
    }

    function getUserGroups(jwtTokenConfig, axios) {
      return axios.get(apiUrl +'/person_group', jwtTokenConfig)
    }

    Promise.all([
      getUsers(jwtTokenConfig, this.axios),
      getUserGroups(jwtTokenConfig, this.axios),

    ])
        .then(results => {

          if(results[0].status===200){
            this.users = results[0].data;
          }
          if(results[1].status===200){
            this.userGroups = results[1].data;
            console.log(this.userGroups)
          }
          this.showLoader=false
        }).catch(error=> {
      this.showLoader=false
      alert("Ошибка загрузки данных, обратитесь к администратору системы!")
      if(error.response.status===401){
        this.$store.dispatch('authentication/logout')
        this.$store.dispatch('alert/clear')
        localStorage.clear()
        router.push('/').then(r => {console.log("error: " + r)})

      }
    });
  }
}



</script>
<style>

.avatar-cropper-close {
  margin-top: 60px !important;
}
.card-img-overlay {
  display: none;
  transition: all 0.5s;
}
.card-img-overlay button {
  margin-top: 20vh;
}
.card:hover .card-img-overlay {
  display: block;
}

.avatar-cropper-close {
  float: right;
  padding: 20px;
  font-size: 3rem;
  color: #263238  !important;
  font-weight: 100;
  text-shadow: 0px 1px rgba(40, 40, 40, 0.3);
}

.avatar-cropper-btn {
  width: 50%;
  padding: 15px 0;
  cursor: pointer;
  border: none;
  outline: none;
  color: #263238  !important;
  background-color: #fff  !important;
}

.avatar-cropper-btn:hover {
  width: 50%;
  padding: 15px 0;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #263238  !important;
  color: #fff  !important;
}

</style>
