<template>
  <v-container>
    <v-card class="ma-auto">
      <v-card-title class="card_ligth_header ma-0 pa-0" >
        <v-row cols=6 class="pa-2 ma-2">
          <v-col cols="12">
            <h3>Библиотека</h3>
          </v-col>
          <v-col cols=6  >
            <v-select
                v-model="librarySearchField"
                :items="librarySearchOptions"
                label="Поле поиска"
                clearable
                item-title="name"
                item-value="value"
                variant="outlined"
                class="small"
            ></v-select>
          </v-col>
          <v-col cols=6>
            <v-text-field
                v-model="librarySearchValue"
                label="Что ищем"
                variant="outlined"
                clearable
                rounded
                append-icon="mdi-account-plus"
                @click:append="libraryDialog=true"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="ma-0 pa-0" >
        <v-row>
          <v-col cols="12">
            <Vue3EasyDataTable
                :headers="libraryHeaders"
                :items="library"
                alternating
                show-index
                :search-field="librarySearchField"
                :search-value="librarySearchValue"
            >
              <template #item-published="{published}">
                <p v-if="published">Да</p>
                <p v-else>нет</p>
              </template>

              <template #item-content="{content}">
                <p v-if="content==='video'">Видео</p>
                <p v-else-if="content==='playlist'">Плейлист</p>
                <p v-else-if="content==='article'">Статья</p>
                <p v-else>{{ content }}</p>
              </template>

              <template #item-method="{method}">
                <p v-if="method==='video'">Видео</p>
                <p v-else-if="method==='waldorf'">Вальдорфская педагогика</p>
                <p v-else-if="method==='montessory'">Монтессори</p>
                <p v-else-if="method==='goldKey'">Золотой ключик</p>
                <p v-else-if="method==='peterson'">Петерсон</p>
                <p v-else-if="method==='elkoninDavydov'">Методика Эльконина-Давыдова</p>
                <p v-else-if="method==='tutors'">Тьюторы</p>
                <p v-else>{{ method }}</p>
              </template>


              <template #item-createDateTime="{createDateTime}">
                {{ createDateTime.replace('T', ' ')}}
              </template>

              <template #item-actions="item">
                <font-awesome-icon style="margin: 5px; color: #1E88E5" @click="editLibItem(item)" icon="fa-solid fa-pen-to-square" />
                <font-awesome-icon style="margin: 5px; color: darkred" @click=" deleteLibItem(item)" icon="fas fa-trash-alt"/>
              </template>
              <template #loading>
                <img
                    src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif"
                    style="width: 100px; height: 80px;"
                />
              </template>

            </Vue3EasyDataTable>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </v-container>

  <v-dialog
      v-model="libraryDialog"
      scrollable
  >
    <v-card max-width="500"
            min-width="350">
      <v-card-title class="card_ligth_header">
        <h5>Статья для библиотеки</h5>
      </v-card-title>
      <v-card-text>
        <v-form
            ref="libraryForm"
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                    label="Название*"
                    v-model="title"
                    :rules="titleRules"
                    hint="Укажите название"
                    :counter="250"
                    variant="outlined"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-select
                    v-model="content"
                    :items="contentTypeOptions"
                    outlined
                    :rules="contentRules"
                    dense
                    variant="outlined"
                    label="Тип контента"
                    item-title="name"
                    item-value="value"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-select
                    v-model="method"
                    :items="methodOptions"
                    :rules="methodRules"
                    outlined
                    dense
                    variant="outlined"
                    label="Метод"
                    item-title="name"
                    item-value="value"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-row
                    class="px-4"
                    fluid
                >
                  <v-switch
                      v-model="published"
                      :label="'Публикация'"
                      color="success"
                  >
                  </v-switch>
                  <v-chip class="ma-2" v-if="published"> Опубликовано</v-chip><v-chip v-else class="ma-2"> Не опубликовано</v-chip>
                </v-row>
              </v-col>

            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <v-btn
            rounded
            variant="outlined"
            @click="closeForm"
        >
          Отмена
        </v-btn>
        <v-btn
            rounded
            variant="outlined"
            @click="save"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog
      v-model="showLoader"
      persistent
  ><v-alert><span class="text-h5 text-primary">Загрузка данных <v-progress-circular
      indeterminate
      color="primary"
  ></v-progress-circular></span></v-alert>
  </v-dialog>
</template>

<script>

import {apiUrl} from "@/service/user.service"
import {router} from "@/service/router";

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list,id) {
  for (var i =0; i< list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

export default {
  name: 'LibraryAdminPage',

  props: [],
  components:{
    Vue3EasyDataTable
  },
  data: () => ({
    library:[],

    libraryDialog: false,

    content:null,
    method:null,
    published:false,
    title:'',
    url:'',

    librarySearchField:'',
    librarySearchValue:'',

    methodItems:[
      {value: "waldorf", name: "Вальдорф"},
      {value: "montessory", name: "Монтессори"},
      {value: "ololo", name: "Метод ололошеньки"},

    ],


    librarySearchOptions: [
      {value: "title", name: "Название"},
      {value: "content", name: "Описание"},
      {value: "method", name: "Метод"},
      {value: "url", name: "url"},

    ],

    libraryHeaders: [
      { text: "id", value: "id" },
      { text: "Название", value: "title" , sortable: true  },
      { text: "Метод", value: "method" , sortable: true},
      { text: "Тип контента", value: "content" , sortable: true},
      { text: "Добавлена", value: "createDateTime" , sortable: true},
      { text: "Опубликована", value: "published" , sortable: true},
      { text: "Действие", value: "actions"},
    ],

    methodOptions: [
      { name: "Вальдорфская педагогика", value: "" },
      { name: "Монтессори", value: "montessory"},
      { name: "Золотой ключик", value: "goldKey"},
      { name: "Петерсон", value: "peterson"},
      { name: "Методика Эльконина-Давыдова", value: "elkoninDavydov"},
      { name: "Тьюторы", value: "tutors"},

    ],

    contentTypeOptions: [
      { name: "Видео", value: "video" },
      { name: "Плейлист", value: "playlist"},
      { name: "Статья", value: "article"}

    ],

    showLoader: false,
    contentRules: [
      v => !!v || 'Поле обязательно для заполнения!'
    ],
    methodRules: [
      v => !!v || 'Поле обязательно для заполнения!',
    ],

    publishedRules: [
      v => !!v || 'Поле обязательно для заполнения!',
    ],
    titleRules: [
      v => (v && v.length <= 251) || 'Значение до должно превышать 50 символов!',
    ],

  }),
  methods: {

    closeForm() {
      this.libraryDialog = false
      this.id = ''
      this.method = null
      this.published = false
      this.title = ''
      this.url = ''
      this.content = null
    },
    save() {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }

      this.$refs.libraryForm.validate().then(responce =>{
        if(responce.valid){

            let person = {
              method:this.method,
              published:this.published,
              title:capitalizeFirstLetter(this.title),
              url:this.url,
              content: this.content
            }

            if (this.id) {
              this.axios.put(apiUrl +'/library/' + this.id, person, jwtTokenConfig)
                  .then(response => {
                    if (response.status === 202) {
                      let index = getIndex(this.library, response.data.id)
                      this.library.splice(index, 1, response.data)
                      this.libraryDialog = false
                      this.id = ''
                      this.method = null
                      this.published = false
                      this.title = ''
                      this.url = ''
                      this.content = null
                    } else if (response.status === 207) {
                      alert(response.data.message)
                      console.log(response)
                    } else {
                      alert("что то пошло не так")
                      console.log(response)
                    }


                  })
                  .catch(error=> {
                    console.log(error)
                  })
            }  else {

              this.axios.post(apiUrl +'/library', person, jwtTokenConfig)
                  .then(response => {

                    if (response.status === 201) {
                      this.library.push(response.data)
                      this.libraryDialog = false
                      this.id = ''
                      this.method = null
                      this.published = false
                      this.title = ''
                      this.content = null
                      this.url = ''
                    } else if (response.status === 207) {
                      alert(response.data.message)
                      console.log(response)
                    } else {
                      alert("что то пошло не так")
                      console.log(response)
                    }
                  })
                  .catch(error=> {
                    if(error.response.status===401){
                      this.$store.dispatch('authentication/logout')
                      this.$store.dispatch('alert/clear')
                      localStorage.clear()
                      router.push('/').then(r => {console.log("error: " + r)})
                    }
                  })
            }


        }
      })

    },
    editLibItem: function (item) {
      this.id = item.id
      this.method = item.method
      this.published = item.published
      this.title = item.title
      this.url = item.url
      this.content = item.content
      this.libraryDialog = true
    },
    deleteLibItem: function (item) {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      this.axios
          .delete(apiUrl +'/library/' + item.id, jwtTokenConfig)
          .then(response => {

            if (response.status === 202) {
              this.library.splice(this.library.indexOf(item), 1)
            } else {
              console.log(response)
            }
          })
          .catch(error=> {
            if(error.response.status===401){
              this.$store.dispatch('authentication/logout')
              this.$store.dispatch('alert/clear')
              localStorage.clear()
              router.push('/').then(r => {console.log("error: " + r)})
            }

          })
    },


  },
  computed: {

    loggedIn() {
      return this.$store.state.authentication.status.loggedIn
    },
    user() {
      return this.$store.state.authentication.user
    },
    authentication() {
      return this.$store.state.authentication
    },

  },
  created: function () {
    this.showLoader=true
    let jwtTokenConfig = {
      headers: {
        Authorization: "Bearer " + this.user.token
      }
    }

    function getLibrary(jwtTokenConfig, axios) {
      return axios.get(apiUrl +'/library/all', jwtTokenConfig)
    }


    Promise.all([
      getLibrary(jwtTokenConfig, this.axios),

    ])
        .then(results => {

          if(results[0].status===200){
            this.library = results[0].data;
          }
          this.showLoader=false
        }).catch(error=> {
      this.showLoader=false
      alert("Ошибка загрузки данных, обратитесь к администратору системы!")
      if(error.response.status===401){
        this.$store.dispatch('authentication/logout')
        this.$store.dispatch('alert/clear')
        localStorage.clear()
        router.push('/').then(r => {console.log("error: " + r)})

      }
    });
  }
}



</script>
<style>


.customize-table {
  --easy-table-border: 1px solid #2a77bb;
  --easy-table-row-border: 1px solid #2a77bb;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #E7EBF3;
  --easy-table-header-background-color: #1E88E5;

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #E7EBF3;
  --easy-table-body-even-row-background-color: rgba(28, 132, 224, 0.26);

  --easy-table-body-row-font-color: #E7EBF3;
  --easy-table-body-row-background-color: rgba(30, 136, 229, 0.49);
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: #1E88E5;
  --easy-table-body-row-hover-background-color: #eee;

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #1E88E5;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;


  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
}

</style>
