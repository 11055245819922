<template>


      <div class="d-flex align-self-center  flex-column justify-center pa-4 mx-auto ma-10">
        <v-card min-width="350" max-width="350" class="align-self-center text-center justify-center" style="opacity: .85;">
          <v-card-title class="card_ligth_header">
            <h5>Мой профиль</h5>
          </v-card-title>
          <v-card-text>
            <v-form ref="profile">
              <v-alert
                  class="ma-2"
                  type="error"
                  v-show="existAlert"
              > {{alertBody}}
              </v-alert>

              <v-avatar  size="128" style="margin: 10px;" @click="showCropper=true" >
                <v-img v-if="avatar!==null"
                    alt="Avatar"
                    :src="avatarUrl"
                ></v-img>
                <span v-if="avatar===null" class="text-h5">{{user.fullName}}</span>

              </v-avatar>
              <v-avatar size="128" style="margin: 10px;" @click="showCropper=true" color="primary">
                <span  class="text-h5">{{user.fullName}}</span>
              </v-avatar>

              <h5 class="text-h6" style="margin: 10px;">{{user.fullName}}</h5>
              <v-divider></v-divider>

              <v-text-field
                  ref="email"
                  v-model="personEmail"
                  label="E-mail"
                  placeholder="my@mail.addr"
                  :rules="emailRules"
                  variant="outlined"
                  required
              ></v-text-field>



              <v-text-field
                  ref="username"
                  v-model="username"
                  label="Имя пользователя (логин)"
                  placeholder="Имя пользователя (логин)"
                  :rules="usernameRules"
                  variant="outlined"
                  required
              ></v-text-field>



              <v-text-field
                  ref="phone"
                  v-model="personMobilePhone"
                  :rules="[() => !!personMobilePhone || 'Поле обязательно',
                            () => !!personMobilePhone && personMobilePhone.length <= 25 || 'Не более 18 символов']"
                  label="Телефон"
                  placeholder="+7-(999)-99-99-999"
                  counter="18"
                  variant="outlined"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="password"
                  label="Пароль*"
                  contenteditable="false"
                  type="text"
                  :rules="passwordRules"
                  hint="Сгенерированный пароль"
                  persistent
                  variant="outlined"
              >
                <template v-slot:append>
                  <v-icon
                      color="red"
                      v-on:click="GeneratePassword"
                  >
                    mdi-hammer
                  </v-icon>
                </template>
              </v-text-field>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="d-flex justify-end card_ligth_actions">
            <v-btn
                variant="outlined"
                rounded
                text @click="saveData"><font-awesome-icon icon="fas fa-save" />
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>



            <avatar-cropper
                @uploading="handleUploading"
                @uploaded="handleUploaded"
                @completed="handleCompleted"
                @error="handlerError"
                v-model="showCropper"
                upload-url="https://navigator-backend.geekbase.ru/api/download/avatar/user"
                :request-options="headers"
                :labels="labels"
            />

</template>


<script>
import {apiUrl, userService} from "@/service/user.service";
  import {router} from "@/service/router";
  import AvatarCropper from 'vue-avatar-cropper';
  export default {
  name: 'ProfileCard',
    components: { AvatarCropper },
    data: () => ({
      errorMessages: '',
      emailRules: [
        v => !!v || 'Укажите адрес электронной почты!',
        value => (value && value.length < 50) || 'не более 50 символов',
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail не валидный'

      ],
      passwordRules: [
        v => !v || /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(v) || 'Не менее 8 символов, хотя бы одна заглавная буква, чило и спец символ(!@#$%^&*)',
      ],
      usernameRules: [
        v => !!v || 'укажите имя пользователя (логин)!',
        value => (value && value.length < 50) || 'не более 50 символов',

      ],
      personEmail: undefined,
      existAlert: false,
      alertBody:'',
      personMobilePhone: undefined,
      password: null,
      formHasErrors: false,
      myProfile: '',
      showCropper: false,
      username:undefined,
      message:'',
      labels:{ submit: 'Сохранить', cancel: 'Отмена' },
      avatar:null,

    }),
    computed:{
      avatarUrl(){
        console.log('https://navigator-backend.geekbase.ru/api/download/avatar/user/' + this.avatar)
        if(this.avatar!==null){

          return 'https://navigator-backend.geekbase.ru/api/download/avatar/user/' + this.avatar
        } else {
          return null
        }

      },
      loggedIn() {
        return this.$store.state.authentication.status.loggedIn
      },
      user() {
        return this.$store.state.authentication.user
      },
      authentication() {
        return this.$store.state.authentication
      },

    headers(){
        let jwtTokenConfig = {
          method: 'POST',
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
          responseType: 'blob'
      }
      return jwtTokenConfig
    }
    },

    methods: {
      handleUploading() {
        this.message = "uploading...";
        console.log("MSG: " + this.message)
      },

      handleUploaded(response) {
        if (response.status === "success") {
          //this.user.avatar = response.url;
          // Maybe you need call vuex action to
          // update user avatar, for example:
          // this.$dispatch('updateUser', {avatar: response.url})
          this.message = "user avatar updated."
          console.log("MSG: " + this.message)
          router.push('/profile').then(r => {
            console.log("error: " + r)
          })
        }
      },

      handleCompleted() {
        this.message = "upload completed.";
        console.log("MSG: " + this.message)

        let jwtTokenConfig = {
          headers: {
            Authorization: "Bearer " + this.user.token
          }
        }

        this.axios
            .get(apiUrl + '/person/i', jwtTokenConfig)
            .then(res => {
              userService.handleResponse(res)
              this.avatar = res.data.personAvatar
              this.personEmail = res.data.personEmail
              this.personMobilePhone = res.data.personMobilePhone
              this.username = res.data.username

            }).catch(error => {
          if(error.response.status===401){
        this.$store.dispatch('authentication/logout')
        this.$store.dispatch('alert/clear')
        localStorage.clear()
        location.reload()

    }
        })

      },

      handlerError() {
        this.message = "Oops! Something went wrong...";
        console.log("MSG: " + this.message)
      },

      saveData() {
        this.$refs.profile.validate().then(responce =>{
          if(responce.valid){
            let jwtTokenConfig = {
              headers: {
                Authorization: "Bearer " + this.user.token
              }
            }

            let person = {
              id: this.user.id,
              personEmail: this.personEmail,
              password: this.password,
              personMobilePhone: this.personMobilePhone,
              userEmail: this.userEmail,
              username: this.username
            }
            this.axios.put(apiUrl + '/person/i/' + this.user.id, person, jwtTokenConfig)
                .then(response => {

                 
                  if (response.status === 202) {
                  //  this.myProfile = response.data
                    this.existAlert = false
                    this.alertBody = ''
                    this.password=''
                  } else if (response.status === 207) {
                    this.existAlert = true
                    this.alertBody = response.data.message
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(function (error) {
                  if(error.response.status===401){
        this.$store.dispatch('authentication/logout')
        this.$store.dispatch('alert/clear')
        localStorage.clear()
        location.reload()

    }
                })
          }
        })

      },

      GeneratePassword() {
        let text = ""
        let possible = "ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz"
        let possibleSimbols = "!@#$%&*"
        let possibleDigits = "123456789"


        for (var i = 0; i < 8; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length))
        text += possibleSimbols.charAt(Math.floor(Math.random() * possibleSimbols.length))
        text += possibleDigits.charAt(Math.floor(Math.random() * possibleDigits.length))

        this.password = text
      },

    },

    created: function () {

      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }

      this.axios
          .get(apiUrl +'/person/i', jwtTokenConfig)
          .then(res => {
            userService.handleResponse(res)
            console.log(res.data)
            this.personEmail = res.data.personEmail
          //  this.personMobilePhone = res.data.personMobilePhone
            this.username = res.data.username
            this.avatar = res.data.personAvatar
            console.log(this.avatar)


          }).catch(error => {
        if(error.response.status===401){
        this.$store.dispatch('authentication/logout')
        this.$store.dispatch('alert/clear')
        localStorage.clear()
        location.reload()

    }
      })

    }
  }
</script>

<style>

  .avatar-cropper-close {
   margin-top: 60px !important;
  }
  .card-img-overlay {
    display: none;
    transition: all 0.5s;
  }
  .card-img-overlay button {
    margin-top: 20vh;
  }
  .card:hover .card-img-overlay {
    display: block;
  }

  .avatar-cropper-close {
    float: right;
    padding: 20px;
    font-size: 3rem;
    color: #263238  !important;
    font-weight: 100;
    text-shadow: 0px 1px rgba(40, 40, 40, 0.3);
  }

  .avatar-cropper-btn {
    width: 50%;
    padding: 15px 0;
    cursor: pointer;
    border: none;
    outline: none;
    color: #263238  !important;
    background-color: #fff  !important;
  }

  .avatar-cropper-btn:hover {
    width: 50%;
    padding: 15px 0;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: #263238  !important;
    color: #fff  !important;
  }

</style>
