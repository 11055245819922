<template>
  <v-layout style=" box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);">
    <v-app-bar
        prominent
        :class="$vuetify.display.smAndDown? 'small_footer_radius' : 'big_footer_radius'"
    >
      <v-app-bar-nav-icon v-if="loggedIn"  variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Навигатор для родителей</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="makeLogout" v-if="loggedIn" variant="text"><font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket"/></v-btn>
      <v-btn @click="loginDialog=true" v-else variant="text"><font-awesome-icon icon="fa-solid fa-arrow-right-to-bracket"/></v-btn>
      <v-btn icon @click="toggleTheme">
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-if="user"
        class="v-navigation-drawer"
        :class="$vuetify.display.smAndDown ? 'big_footer_radius' : 'small_footer_radius'"
        v-model="drawer"
        permanent
        expand-on-hover
        rail
    >
      <v-list  :class="$vuetify.display.smAndUp ? 'big_footer_radius' : 'small_footer_radius'">
        <v-list-item
                     :title="myProfile.firstname"
                     :subtitle=myProfile.userEmail
        ></v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list density="compact" nav   :class="$vuetify.display.smAndUp ? 'big_footer_radius' : 'small_footer_radius'">
        <v-list-item prepend-icon="mdi-home" title="Домой"  @click="sidebarItem=0" ></v-list-item>
        <v-list-item prepend-icon="mdi-account-settings" title="Профиль"  @click="sidebarItem=1"></v-list-item>
        <v-list-item prepend-icon="mdi-account-cog" title="Пользователи" @click="sidebarItem=2" ></v-list-item>
        <v-list-item prepend-icon="mdi-library" title="Библиотека" @click="sidebarItem=3" ></v-list-item>
        <v-list-item prepend-icon="mdi-school" title="Курсы" @click="sidebarItem=4" ></v-list-item>
        <v-list-item prepend-icon="mdi-frequently-asked-questions" title="FAQ" @click="sidebarItem=5" ></v-list-item>
      </v-list>
    </v-navigation-drawer>


    <v-main
        class="bg-success align-self-start"
        :class="($vuetify.display.smAndDown)&&(sidebarItem===0) ? 'small_footer_radius_main' : 'big_footer_radius_main'"
    >

      <div
          v-if="Number(sidebarItem)!==0"
          grid-list-md
          text-xs-center
          style="display: flex;
          min-height: 92vh;
          flex-direction: column;
          justify-content: space-between;">
        <v-layout row wrap>
          <v-container class="align-self-start my-10" >
            <ProfileCard v-if="Number(sidebarItem)===1"></ProfileCard>
            <AdminUsersPage v-if="Number(sidebarItem)===2"></AdminUsersPage>
            <LibraryAdminPage v-if="Number(sidebarItem)===3"></LibraryAdminPage>
            <CourceAdminPage v-if="Number(sidebarItem)===4"></CourceAdminPage>
            <FaqAdminPage v-if="Number(sidebarItem)===5"></FaqAdminPage>
          </v-container>
        </v-layout>
      </div>
      <div v-else>
        <v-parallax
            :src="$vuetify.display.smAndDown ? require('./static/phone_xs_gradient.jpeg') : require('./static/DSC02012.webp')"
        >
          <div class="d-flex flex-column fill-height justify-center align-center">
            <h1 class="text-lg-h1 text-xs-h4 text-md-h3 font-weight-black mb-6" style="color: #ffffff">
              «Эврика»
            </h1>
            <h4 class="subheading text-lg-h6 text-xs-h8 text-md-h7 font-weight-black mb-2" style="color:#ffffff;">
              информационная система
            </h4>
            <v-btn
                rounded="pill"
                size="x-large"
                variant="outlined"
                class="ma-8"
                color="#ffffff"
                v-scroll-to="'#about-school'"
            >
              <v-icon color="#ffffff">mdi-chevron-double-down</v-icon>
            </v-btn>
          </div>

        </v-parallax>

        <v-dialog
            v-model="loginDialog"
            persistent
        >
          <v-card >
            <v-card-title class="card_ligth_header">
              <span class="text-h5">Вход в систему</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-form ref="loginForm" lazy-validation>
                <v-text-field
                    name="username"
                    label="Имя пользователя"
                    :rules="usernameRules"
                    v-model="username"
                    hide-details="auto"
                    outlined
                    aria-required="true"
                    class="ma-2"
                    style="min-width: 350px; max-width: 350px;"
                >
                </v-text-field>
                <v-text-field
                    id="password"
                    name="password"
                    label="Пароль"
                    type="password"
                    :rules="passwordRules"
                    v-model="password"
                    outlined
                    aria-required="true"
                    class="ma-2"
                    style="min-width: 350px; max-width: 350px;"
                ></v-text-field>
                <v-alert
                    density="compact"
                    style="min-width: 350px; max-width: 350px;"
                    :type="alert.type"
                    v-if="alert.type!=null"
                    class="ma-2"
                >
                  {{ alert.message }}
                </v-alert>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="card_ligth_actions">
              <v-spacer></v-spacer>
              <v-btn
                  variant="outlined"
                  rounded
                  text
                  @click="closeLoginDialog"
              >
                Отмена
              </v-btn>
              <v-btn

                  variant="outlined"
                  rounded
                  :disabled="loggingIn"
                  @click="makeLogin"
              >
                Войти
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
<div style="display: flex;
      flex-direction: column;
      " class="align-self-start">
  <site-footer ></site-footer>
</div>


    </v-main>


  </v-layout>
</template>

<script>
import {router} from "@/service/router"
import ProfileCard from '@/components/Profile/ProfileCard.vue'
import AdminUsersPage from '@/components/Admin/AdminUsersPage.vue'
import LibraryAdminPage from "@/components/Admin/LibraryAdminPage";
import CourceAdminPage from "@/components/Admin/CourceAdminPage";
import FaqAdminPage from "@/components/Admin/FaqAdminPage";

import SiteFooter from "@/components/sections/SiteFooter";


import { useTheme } from 'vuetify'
import {apiUrl} from "@/service/user.service";




export default {
  name: 'HomePage',
  setup () {
    const theme = useTheme()

    if (localStorage.colorTheme) {
      console.log("theme exist :" + localStorage.colorTheme)
      theme.global.name.value = localStorage.colorTheme
    } else {
      theme.global.name.value = 'myCustomLightTheme'
    }

    return {
      theme,
      toggleTheme: () => {

        if(theme.global.current.value.dark) {
          localStorage.colorTheme = 'myCustomLightTheme'
          theme.global.name.value = 'myCustomLightTheme'
          console.log("set ligth")
        } else {
          localStorage.colorTheme = 'dark'
          theme.global.name.value = 'dark'
          console.log("set dark")
        }
      }
    }
  },
  components: {
    ProfileCard,
    AdminUsersPage,
    SiteFooter,
    LibraryAdminPage,
    CourceAdminPage,
    FaqAdminPage
  },
  watch: {
    sidebarItem(newSidebarItem) {
      console.log("newSidebarItem: " + newSidebarItem)
      if(localStorage.ProfileSidebarItem !== newSidebarItem){
        localStorage.ProfileSidebarItem = newSidebarItem;
      }
    },

    loggedIn(newLoggedIn){
      if(newLoggedIn){
        this.loginDialog=false
        console.log("User successful login")
        location.reload()
      } else {
        console.log("User not login")
      }
    },

  },
  mounted() {
    if (localStorage.ProfileSidebarItem) {
      this.sidebarItem = JSON.parse(localStorage.ProfileSidebarItem)
    } else {
      this.sidebarItem=0
    }
  },
  data () {
    return {
      drawer: true,
      sidebarItem: 0,
      pdAgree:false,
      loginDialog:false,
      usernameRules: [
        v => !!v || 'Укажите имя пользователя!',
      ],
      passwordRules: [
        v => !!v || 'Укажите пароль!',
      ],
      username: '',
      password: '',
      rules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 3) || 'Min 3 characters',
      ],
      myProfile: {},
      features: [
        {
          icon: 'mdi-school',
          title: 'Портфолио',
          text: 'Фиксация достижений обучающегося в электронном портфолио на протяжении всего процесса обучения. ' +
              'Родители могут следить за достижениями своих детей  в личном кабинете.',
        },
        {
          icon: 'mdi-clock',
          title: 'Расписание',
          text: 'Электронное расписание занятий, доступное в личном кабинете для детей и и родителей. ' +
              'Возможность посмотреть планируемые мероприятия и задание для самостоятельной работы к ним, записаться на посещение ' +
              'вариативного занятия или отменить запись в случае если ребенок решил изменить свой выбор.',
        },
        {
          icon: 'mdi-webcam',
          title: 'Вебинары',
          text: 'Организация вебинаров и вебинарных занятий на базе информационной системы школы. ' +
              'Встреча может быть организована как для узкого круга лиц, так и для больших групп. Принимать участие ' +
              'можно как с компьютера, так и с мобильных устройств без установки сторонних приложений, нужен только ' +
              'браузер с поддержкой технологии HTML5.',
        },
      ],
      stats: [
        ['24k', 'Github Stars'],
        ['330+', 'Releases'],
        ['1m', 'Downloads/mo'],
        ['5m', 'Total Downloads'],
      ],
      menu: [
        { text: 'Главная', link: '/' },
        { text: 'Пользовательское соглашение', link: '/user_agreement' },
        { text: 'Политика обработки ПД', link: '/personal_data_politic' },
      ],
    }
  },
  computed: {

    loggingIn () {
      return this.$store.state.authentication.status.loggingIn
    },
    isParent() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_PARENT')
      } else return false

    },
    isChild() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_CHILD')
      } else return false

    },
    isTeacher() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_TEACHER')
      } else return false

    },
    isTutor() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_TUTOR')
      } else return false
    },

    loggedIn () {

      return this.$store.state.authentication.status.loggedIn
    },
    user () {

      return this.$store.state.authentication.user
    },
    authentication () {
      return this.$store.state.authentication
    },
    alert () {
      console.log('this.$store.state.alert' + this.$store.state.alert)
      return this.$store.state.alert

    }

  },

  methods: {

    closeLoginDialog(){
      this.$store.dispatch('alert/clear')
      this.password=''
      this.username=''
      this.loginDialog=false
    },

    makeLogin () {
        this.$refs.loginForm.validate().then(responce => {
          if (responce.valid){
            const { username, password } = this
            const { dispatch } = this.$store
            dispatch('authentication/login', { username, password })
            console.log("this.authentication.status: " + this.authentication.status)

          }
        }).then(result => {
          console.log("result: " + result)
          if(this.authentication.status.loggedIn===true){
            this.loginDialog= false
          }
        } )


    },
      makeLogout () {
        this.$store.dispatch('authentication/logout')
        this.$store.dispatch('alert/clear')
        this.sidebarItem=0

      },

    goToAdminArea() {
      router.push('/admin').then(r => {console.log("error: " + r)})

    }
  },
  created: function () {

    if(this.user!==null){
      let jwtTokenConfig = {
        headers: {
        Authorization: "Bearer " + this.$store.state.authentication.user.token
      }
    }
    this.axios.get(apiUrl +'/person/i', jwtTokenConfig)
        .then(responce=>{
          this.myProfile = responce.data
          console.log('myProfile: ', responce.data)

    }).catch(error => {
    console.log(error)
    })
} else {
      this.sidebarItem=0
    }
  }
}


</script>
<style>
#app {
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
body {
  font-family: "Montserrat";
}
.title {
  font-size: 1.25rem!important;
  font-weight: 500;
  letter-spacing: .0125em!important;
}
.big_footer_radius {
  border-bottom-left-radius:20px;
  border-bottom-right-radius: 20px;
  opacity: .85;
  background-color: #ffffff!important;
  color: #1E88E5!important;
}
.big_footer_radius_main {
  margin-top: -65px;
}

.small_footer_radius {
  border-bottom-left-radius:5px;
  border-bottom-right-radius: 5px;
  opacity: .9;
  background-color: #ffffff!important;
  color: #1E88E5!important;
}
.small_footer_radius_main {
  margin-top: -5px;

}
.card_ligth_header {

  background-color: #ffffff!important;
  color: #1E88E5!important;

}
.card_ligth_actions {

  background-color: #ffffff!important;
  color: #1E88E5!important;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.v-chip{
  background-color: #1E88E5!important;
  color: #ffffff!important;
}
.v-card{
  box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
  opacity: .95;

}

</style>