<template>
  <v-container>
    <v-card class="ma-auto">
      <v-tabs v-model="tab" background-color="primary" >
        <v-tab value="1" >Курсы</v-tab>
        <v-tab value="2">Отзывы</v-tab>
      </v-tabs>
      <v-card-text class="align-self-stretch  ma-0 pa-0">
        <v-window v-model="tab" class="align-self-stretch">
          <v-window-item value="1" class="align-self-stretch">
            <v-card-title class="card_ligth_header ma-0 pa-0" >
              <v-row cols=6 class="pa-2 ma-2">
                <v-col cols="12">
                  <h3>Курсы</h3>
                </v-col>
                <v-col cols=6  >
                  <v-select
                      v-model="courseSearchField"
                      :items="courseSearchOptions"
                      label="Поле поиска"
                      clearable
                      item-title="name"
                      item-value="value"
                      variant="outlined"
                      class="small"
                  ></v-select>
                </v-col>
                <v-col cols=6>
                  <v-text-field
                      v-model="courseSearchValue"
                      label="Что ищем"
                      variant="outlined"
                      clearable
                      rounded
                      append-icon="mdi-account-plus"
                      @click:append="courseDialog=true"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="ma-0 pa-0" >
              <v-row>
                <v-col cols="12">
                  <Vue3EasyDataTable
                      :headers="courseHeaders"
                      :items="courses"
                      alternating
                      show-index
                      :search-field="courseSearchField"
                      :search-value="courseSearchValue"
                  >
                    <template #item-active="{active}">
                      <p v-if="active">Да</p>
                      <p v-else>нет</p>
                    </template>

                    <template #item-coach="{coach}">
                      {{coach.firstname}} {{coach.lastname}}
                    </template>

                    <template #item-createDateTime="{createDateTime}">
                      {{ createDateTime.replace('T', ' ')}}
                    </template>

                    <template #item-actions="item">
                      <font-awesome-icon style="margin: 5px; color: #1E88E5" @click="editCourse(item)" icon="fa-solid fa-pen-to-square" />
                      <font-awesome-icon style="margin: 5px; color: darkred" @click="deleteCourse(item)" icon="fas fa-trash-alt"/>
                    </template>
                    <template #loading>
                      <img
                          src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif"
                          style="width: 100px; height: 80px;"
                      />
                    </template>

                  </Vue3EasyDataTable>
                </v-col>
              </v-row>

            </v-card-text>
          </v-window-item>
          <v-window-item value="2" class="align-self-stretch">
            <v-card-title class="card_ligth_header ma-0 pa-0" >
              <v-row cols=6 class="pa-2 ma-2">
                <v-col cols="12">
                  <h3>Отзывы</h3>
                </v-col>
                <v-col cols=12>
                  <v-text-field
                      v-model="courseFeedbackSearchValue"
                      label="Что ищем"
                      variant="outlined"
                      clearable
                      rounded
                      append-icon="mdi-account-plus"
                      @click:append="courseFeedbackDialog=true"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="ma-0 pa-0" >
              <v-row>
                <v-col cols="12">
                  <Vue3EasyDataTable
                      :headers="courseFeedbackHeaders"
                      :items="courseFeedbacks"
                      alternating
                      show-index
                      :search-value="courseFeedbackSearchValue"
                  >
                    <template #item-moderated="{moderated}">
                      <p v-if="!!moderated">Да</p>
                      <p v-else>нет</p>
                    </template>

                    <template #item-person="{person}">
                      {{person.firstname}} {{person.lastname}}
                    </template>

                    <template #item-createDateTime="{createDateTime}">
                      {{ createDateTime.replace('T', ' ')}}
                    </template>

                    <template #item-actions="item">
                      <font-awesome-icon style="margin: 5px; color: #1E88E5" @click="editCourseFeedback(item)" icon="fa-solid fa-pen-to-square" />
                      <font-awesome-icon style="margin: 5px; color: darkred" @click="deleteCourseFeedback(item)" icon="fas fa-trash-alt"/>
                    </template>

                  </Vue3EasyDataTable>
                </v-col>
              </v-row>

            </v-card-text>
          </v-window-item>
        </v-window>
      </v-card-text>

    </v-card>
  </v-container>
  <v-dialog
      v-model="courseFeedbackDialog"
      scrollable
  >
    <v-card max-width="500"
            min-width="350">
      <v-card-title class="card_ligth_header">
        <h5>Отзыв</h5>
      </v-card-title>
      <v-card-text v-if="!!courseFeedbackDialog">
        <v-form
            ref="feedbackForm"
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <p>Автор: {{person.firstname}} {{person.lastname}}</p>
              </v-col>

              <v-col cols="12">
                <v-textarea
                    label="Отзыв*"
                    v-model="description"
                    :rules="descriptionRules"
                    :counter="1500"
                    variant="outlined"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-text-field
                    label="Рейтинг*"
                    v-model="rate"
                    :rules="descriptionRules"
                    :counter="3"
                    variant="outlined"
                    required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-row
                    class="px-4"
                    fluid
                >
                  <v-switch
                      v-model="moderated"
                      :label="'модерация пройдена'"
                      color="success"
                  >
                  </v-switch>
                  <v-chip class="ma-2" v-if="!!moderated"> Да</v-chip>
                  <v-chip v-else class="ma-2"> Нет</v-chip>
                </v-row>
              </v-col>

            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <v-btn
            rounded
            variant="outlined"
            @click="closeFeedbackForm"
        >
          Отмена
        </v-btn>
        <v-btn
            rounded
            variant="outlined"
            @click="saveFeedback"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog
      v-model="courseDialog"
      scrollable
  >
    <v-card max-width="500"
            min-width="350">
      <v-card-title class="card_ligth_header">
        <h5>Курс</h5>
      </v-card-title>
      <v-card-text>
        <v-form
            ref="courseForm"
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Название*"
                    v-model="title"
                    :rules="titleRules"
                    hint="Укажите название"
                    :counter="100"
                    variant="outlined"
                    required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                    label="Описание*"
                    v-model="description"
                    :rules="descriptionRules"
                    :counter="250"
                    variant="outlined"
                    hint="Укажите описание"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                    v-model="coach"
                    :items="coachs"
                    outlined
                    dense
                    chips
                    small-chips
                    closable-chips
                    item-title="firstname"
                    item-value="id"
                    :rules ="coachRules"
                    label="Преподаватель"
                >
                  <template v-slot:chip="{ props, item }">
                    <v-chip
                        v-bind="props"
                        :text="item.raw.firstname +' '+item.raw.lastname"
                    ></v-chip>
                  </template>
                  <template v-slot:item="{ props, item }">

                    <v-list-item
                        v-bind="props"
                        :title="item.raw.firstname +' '+item.raw.lastname"
                        :subtitle="item.raw.username"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-select
                    v-model="lessonsQuantity"
                    :items="courseLessonCountItems"
                    outlined
                    dense
                    chips
                    small-chips
                    variant="outlined"
                    label="Занятий"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-select
                    v-model="stars"
                    :items="starsItems"
                    outlined
                    dense
                    chips
                    small-chips
                    variant="outlined"
                    label="Рейтинг"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-row
                    class="px-4"
                    fluid
                >
                  <v-switch
                      v-model="active"
                      :label="'Активен'"
                      color="success"
                  >
                  </v-switch>
                  <v-chip class="ma-2" v-if="active"> Да</v-chip>
                  <v-chip v-else class="ma-2"> Нет</v-chip>
                </v-row>
              </v-col>

            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <v-btn
            rounded
            variant="outlined"
            @click="closeForm"
        >
          Отмена
        </v-btn>
        <v-btn
            rounded
            variant="outlined"
            @click="saveCourse"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog
      v-model="showLoader"
      persistent
  ><v-alert><span class="text-h5 text-primary">Загрузка данных <v-progress-circular
      indeterminate
      color="primary"
  ></v-progress-circular></span></v-alert>
  </v-dialog>
</template>

<script>

import {apiUrl} from "@/service/user.service"
import {router} from "@/service/router";

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list,id) {
  for (var i =0; i< list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

export default {
  name: 'courseAdminPage',



  props: [],
  components:{
    Vue3EasyDataTable
  },
  data: () => ({
    tab:1,
    courses:[],
    coachs:[],
    feedbacks:[],
    courseDialog: false,

    courseFeedbackDialog:false,
    personId: null,
    courseId: null,
    person: null,
    creatorId: null,
    rate: null,
    moderated: null,


    id:'',
    title:'',
    active:false,
    coach:null,
    coachId: null,
    description:'',
    img:  null,
    lessonsQuantity:0,
    stars: 0,

    courseSearchField:'',
    courseSearchValue:'',

    courseFeedbackSearchField:'',
    courseFeedbackSearchValue:'',

    starsItems:[0, 1, 2, 3 ,4 ,5],

    courseLessonCountItems:[0, 1, 2, 3 ,4 ,5, 6, 7, 8 , 9, 10],

    courseSearchOptions: [
      {value: "title", name: "Название"},
      {value: "description", name: "Описание"},
      {value: "createDateTime", name: "Создан"},
      {value: "active", name: "Активен"},
      {value: "lessonsQuantity", name: "Рейтинг"},
      {value: "stars", name: "Занятий"},

    ],

    courseHeaders: [
      { text: "id", value: "id" },
      { text: "Название", value: "title" , sortable: true  },
      { text: "Описание", value: "description" , sortable: true},
      { text: "Создан", value: "createDateTime" , sortable: true},
      { text: "Активен", value: "active" , sortable: true},
      { text: "Преподаватель", value: "coach" , sortable: true},
      { text: "Занятий", value: "lessonsQuantity" , sortable: true},
      { text: "Рейтинг", value: "stars" , sortable: true},
      { text: "Действие", value: "actions"},
    ],
    courseFeedbackHeaders: [
      { text: "id", value: "id" },
      { text: "Автор", value: "person" , sortable: true  },
      { text: "Курс", value: "courseId" , sortable: true},
      { text: "Отзыв", value: "description" , sortable: true},
      { text: "Создан", value: "createDateTime" , sortable: true},
      { text: "Рейтинг", value: "rate" , sortable: true},
      { text: "Модерация", value: "moderated" , sortable: true},
      { text: "Действие", value: "actions"},
    ],

    showLoader: false,
    descriptionRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 250) || 'Значение до должно превышать 50 символов!',
    ],
    methodRules: [
      v => !!v || 'Поле обязательно для заполнения!',
    ],
    publishedRules: [
      v => !!v || 'Поле обязательно для заполнения!',
    ],
    titleRules: [
      v => (v && v.length <= 100) || 'Значение до должно превышать 50 символов!',
    ],
    coachRules: [
      v => (!!v && true)  || 'Поле обязательно для заполнения!'
    ],

  }),
  methods: {
    closeFeedbackForm(){
      this.courseFeedbackDialog = false
      this.id = null
      this.personId = null
      this.courseId=null
      this.person = null
      this.description = ''
      this.rate = null
      this.creatorId = null
      this.createDateTime = null
      this.moderated = null

    },
    saveFeedback(){
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }

      this.$refs.feedbackForm.validate().then(responce =>{
        if(responce.valid){

          let feedback = {
            description: this.description,
            moderated:this.moderated,
            rate: this.rate
          }

          if (this.id) {
            this.axios.put(apiUrl +'/course/review/' + this.id, feedback, jwtTokenConfig)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.feedbacks, response.data.id)
                    this.feedbacks.splice(index, 1, response.data)
                    this.courseFeedbackDialog = false
                    this.id = ''
                    this.personId = ''
                    this.courseId= ''
                    this.person = ''
                    this.description = ''
                    this.rate = ''
                    this.creatorId = ''
                    this.createDateTime = ''
                    this.moderated = ''



                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }


                })
                .catch(error=> {
                  console.log(error)
                })
          }


        }
      })
    },


    closeForm() {
      this.courseDialog = false
      this.id = ''
      this.title = ''
      this.description=''
      this.active = false
      this.coachId = null
      this.coach = null
      this.lessonsQuantity =0
      this.stars = 0
    },
    saveCourse() {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }

      this.$refs.courseForm.validate().then(responce =>{
        if(responce.valid){
          if(this.coach instanceof Object){
            this.coach = this.coach.id
          }

          let course = {
            title:capitalizeFirstLetter(this.title),
            description:capitalizeFirstLetter(this.description),
            coachId:this.coach,
            active: this.active,
            published:this.published,
            lessonsQuantity: this.lessonsQuantity,
            stars: this.stars,
            img: this.img
          }

          if (this.id) {
            this.axios.put(apiUrl +'/course/' + this.id, course, jwtTokenConfig)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.courses, response.data.id)
                    this.courses.splice(index, 1, response.data)
                    this.courseDialog = false
                    this.id = ''
                    this.title = ''
                    this.description=''
                    this.active = false
                    this.coachId = null
                    this.coach = null
                    this.lessonsQuantity =0
                    this.stars = 0

                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }


                })
                .catch(error=> {
                  console.log(error)
                })
          }  else {

            this.axios.post(apiUrl +'/course', course, jwtTokenConfig)
                .then(response => {

                  if (response.status === 201) {
                    this.courses.push(response.data)
                    this.courseDialog = false
                    this.id = ''
                    this.title = ''
                    this.description=''
                    this.active = false
                    this.coachId = null
                    this.coach = null
                    this.lessonsQuantity =0
                    this.stars = 0
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  if(error.response.status===401){
                    this.$store.dispatch('authentication/logout')
                    this.$store.dispatch('alert/clear')
                    localStorage.clear()
                    router.push('/').then(r => {console.log("error: " + r)})
                  }
                })
          }


        }
      })

    },
    editCourse: function (item) {
      this.id = item.id
      this.title = item.title
      this.description=item.description
      this.active = item.active
      this.coachId = item.coachId
      this.coach = item.coach
      this.lessonsQuantity =item.lessonsQuantity
      this.stars = item.stars
      this.courseDialog = true
    },
    deleteCourse: function (item) {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      this.axios
          .delete(apiUrl +'/course/' + item.id, jwtTokenConfig)
          .then(response => {

            if (response.status === 202) {
              this.courses.splice(this.courses.indexOf(item), 1)
            } else {
              console.log(response)
            }
          })
          .catch(error=> {
            if(error.response.status===401){
              this.$store.dispatch('authentication/logout')
              this.$store.dispatch('alert/clear')
              localStorage.clear()
              router.push('/').then(r => {console.log("error: " + r)})
            }

          })
    },

    editCourseFeedback: function (item) {
      this.id = item.id
      this.personId = item.personId
      this.courseId=item.courseId
      this.person = item.person
      this.description = item.description
      this.rate = item.rate
      this.creatorId =item.creatorId
      this.createDateTime = item.createDateTime
      this.moderated = item.moderated
      this.courseFeedbackDialog = true
    },

    deleteCourseFeedback: function (item) {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      this.axios
          .delete(apiUrl +'/course/review/' + item.id, jwtTokenConfig)
          .then(response => {

            if (response.status === 202) {
              this.feedbacks.splice(this.feedbacks.indexOf(item), 1)
            } else {
              console.log(response)
            }
          })
          .catch(error=> {
            if(error.response.status===401){
              this.$store.dispatch('authentication/logout')
              this.$store.dispatch('alert/clear')
              localStorage.clear()
              router.push('/').then(r => {console.log("error: " + r)})
            }

          })
    },


  },
  computed: {
    courseFeedbacks(){
      if(this.feedbacks.length>0) {
        return this.feedbacks
      } else return null
    },

    loggedIn() {
      return this.$store.state.authentication.status.loggedIn
    },
    user() {
      return this.$store.state.authentication.user
    },
    authentication() {
      return this.$store.state.authentication
    },

  },
  created: function () {
    this.showLoader=true
    let jwtTokenConfig = {
      headers: {
        Authorization: "Bearer " + this.user.token
      }
    }

    function getCourses(jwtTokenConfig, axios) {
      return axios.get(apiUrl +'/course/all', jwtTokenConfig)
    }
    function getCoachs(jwtTokenConfig, axios) {
      return axios.get(apiUrl +'/person/coach', jwtTokenConfig)
    }
    function getCourseFeedbacks(jwtTokenConfig, axios) {
      return axios.get(apiUrl +'/course/review/all', jwtTokenConfig)
    }


    Promise.all([
      getCourses(jwtTokenConfig, this.axios),
      getCoachs(jwtTokenConfig, this.axios),
      getCourseFeedbacks(jwtTokenConfig, this.axios),

    ])
        .then(results => {

          if(results[0].status===200){
            this.courses = results[0].data;
          }
          if(results[1].status===200){
            this.coachs = results[1].data;
          }
          if(results[2].status===200){
            this.feedbacks = results[2].data;
          }
          this.showLoader=false
        }).catch(error=> {
      this.showLoader=false
      alert("Ошибка загрузки данных, обратитесь к администратору системы!")
      if(error.response.status===401){
        this.$store.dispatch('authentication/logout')
        this.$store.dispatch('alert/clear')
        localStorage.clear()
        router.push('/').then(r => {console.log("error: " + r)})

      }
    });
  }
}



</script>
<style>




</style>
